<template>
    <div>
        <v-card elevation="0" class=" mx-auto mb-5">
            <v-toolbar elevation="0" color="primary" dark dense>
                <v-toolbar-title>Tilføj pressesider</v-toolbar-title>
                <v-spacer/>
            </v-toolbar>
            <v-card-text>
                <span class="text--primary">Copy/paste fra regneark.</span>
                <v-textarea
                    v-model="lines"
                    outlined
                    hide-details
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-checkbox label="Headers inkl." class="mr-2" @click="tsvHeadersInc = !tsvHeaders"></v-checkbox>
                <v-btn elevation="0" color="primary" :loading="loading" :disabled="loading" @click="parseInput()">
                    Tilføj
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card elevation="0" class=" mx-auto">
            <v-toolbar elevation="0" color="primary" dark dense>
                <v-toolbar-title>Resultat</v-toolbar-title>
            </v-toolbar>
            <v-card-text>

                <v-textarea
                    v-model="result"
                    outlined
                    hide-details
                ></v-textarea>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import api from "../../services/api";
    export default {
        name: "addSites",
        data() {
            return {
                tsvHeaders: ['domain', 'domainRating', 'referringDomains', 'category', 'price', 'note', 'casino', 'loans', 'provider', 'textInclusive'],
                tsvHeadersInc: false,
                lines: 'teamhansen.dk\t8\t\tHus, have og GDS, Bolig og interiør, Baby og børn, Livsstil , Diverse, PBN, Lån tilladt, Gambling tilladt, Pharma tilladt\t334\tOBS: Kun 1 link\t\t\tSEOW\tPlus 150 kr.\n',
                result: '',
                headers: [
                    {text: 'Domain', value: 'domain'},
                    {text: 'Domain rating', value: 'domainRating'},
                    {text: 'Referring domains', value: 'referringDomains'},
                    {text: 'Kategori', value: 'category'},
                    {text: 'Pris', value: 'price'},
                    {text: 'Note', value: 'note'},
                    {text: 'Casino', value: 'casino'},
                    {text: 'Lån', value: 'loans'},
                    {text: 'Udbyder', value: 'provider'},
                    {text: 'Tekst', value: 'textInclusive'},
                ],
                items: [],
                loading: false,
            }
        },
        methods: {
            parseTsv() {
                if (this.lines.trim() === "") {
                    return
                }
                const lines = this.lines.split('\n')
                const headers = this.tsvHeadersInc ? lines.shift().split('\t') : this.tsvHeaders
                return lines.map(line => {
                    const data = line.split('\t')
                    return headers.reduce((obj, nextKey, index) => {
                        obj[nextKey] = data[index]
                        return obj
                    }, {})
                })
            },
            extractCategories(results) {

                let categories = []
                for (let site of results) {
                    if (typeof site.category !== 'undefined') {
                        categories.push(...site.category.split(', '))
                    } else {
                        categories.push([])
                    }
                }
                let cats = [...new Set(categories)]
                return cats.join(',')
            },
            parseInput() {
                this.loading = true
                let json = this.parseTsv()
                if (typeof json === 'undefined') {
                    return
                }
                let categories = this.extractCategories(json)
                let lineCats
                api.get('/sites/categories')
                    .then(response => {
                        json.forEach((v, i) => {
                            if (typeof v.category !== 'undefined' && v.category.trim() !== "") {
                                lineCats = v.category.split(', ')
                            }
                            json[i].category = [] // Reset to array in order to push IDs
                            json[i].loans = json[i].loans == "OK" ? 1 : 0
                            json[i].price = json[i].price.replace('.', '').replace(',', '.')
                            json[i].domainRating = json[i].domainRating == "" ? null : json[i].domainRating * 1
                            json[i].referringDomains = json[i].referringDomains == "" ? null : json[i].domainRating * 1
                            json[i].casino = json[i].casino == "Nej" ? 0 : 1
                            lineCats.forEach((c, k) => {
                                response.data.find(x => x.name == c) && json[i].category.push(response.data.find(x => x.name == c).id)
                            })

                        })
                        return json
                    })
                    .then(json => {
                        api.post('/sites', json)
                            .then(response => {
                                console.log(response.data)
                                this.lines = ''
                                this.loading = false
                                this.result = response.data.length + ' Side(r) indsat'
                            })
                    })

            },
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>