<template>
    <v-card elevation="0" color="transparent">
        <v-toolbar elevation="0" dense color="transparent">
            <table-view/>
            <v-toolbar-title>Registrerede brugere</v-toolbar-title>
            <v-spacer/>
            <add-user/>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="users"
            :mobile-breakpoint="$store.state.dataTables.breakPoint"
            dense
            class="transparent"
        >
            <template v-slot:item.role="{item}">
                <v-select
                    :items="roles"
                    :search="userSearch"
                    dense
                    hide-details
                    item-text="name"
                    item-value="role"
                    style="border-color:rgba(0,0,0,0)"
                    v-model="item.role"
                    @change="setUserRole(item)"
                />
            </template>
            <template v-slot:item.active="{item}">
                <v-simple-checkbox
                    :ripple="false"
                    color="primary"
                    v-model="item.active"
                    @click="userStatus(item.uid)"
                />
            </template>
        </v-data-table>
        <div class="mt-4 mb-4">
        </div>
    </v-card>
</template>

<script>
    import api from "../../services/api";
    import AddUser from "./addUser";
    import TableView from "../tableView";
    import userManager from "../../services/userManager";

    export default {
        name: "userManagement",
        components: {TableView, AddUser},
        data() {
            return {
                users: [],
                userSearch: '',
                headers: [
                    {text: 'Navn', value: 'name'},
                    {text: 'E-mail', value: 'mail'},
                    {text: 'Rolle', value: 'role', width: 260},
                    {text: 'Aktiv', value: 'active', width: 60},
                ],
                roles: [
                    {text: 'sysDev', role: 1},
                    {text: 'Ikke bekræftet', role: 0},
                ],
                defaultSelected: {
                    name: "John",
                    last: "Doe"
                },
                breakPoint: 0,
            }
        },
        methods: {
            getUsers() {
                api.get('users')
                  .then(response => {
                      this.users = response.data
                  })
            },
            getRoles(){
                api.get('user-management/roles')
                .then(response=>{
                    this.roles = response.data
                })
            },
            userStatus(uid) {
                this.users.find(x => x.uid === uid).active ? userManager.activateUser(uid) : userManager.deactivateUser(uid)
            },
            setUserRole(item) {
                userManager.setUserRole(item.uid, item.role)
            }
        },
        mounted() {
            this.getUsers()
            this.getRoles()
        }
    }
</script>

<style>
    .v-data-table__wrapper .v-input__slot:before {
        border-color: rgba(0, 0, 0, 0) !important;
    }
</style>