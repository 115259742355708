var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-toolbar',{attrs:{"elevation":"0","color":"transparent","dense":""}},[_c('v-toolbar-items',_vm._l((_vm.permissions),function(role,idx){return _c('v-btn',{key:role.role,attrs:{"elevation":"0","color":"transparent"},on:{"click":function($event){return _vm.setRole(idx)}}},[_vm._v(" "+_vm._s(role.name)+" ")])}),1),_c('v-spacer'),_c('v-btn',{attrs:{"right":"","text":""},on:{"click":function($event){_vm.newAreaDialog = !_vm.newAreaDialog}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Område ")],1)],1),_c('v-divider'),_c('v-sheet',{staticClass:"transparent"},[_c('v-card-subtitle',[_vm._v(" Du ser nu rettigheder for "),_c('span',{staticClass:"font-weight-bold text-decoration-underline"},[_vm._v(_vm._s(_vm.permissions[_vm.currentRole].name))]),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{staticClass:"transparent",attrs:{"items":_vm.permissions[_vm.currentRole].permissions,"headers":_vm.permissionHeaders,"sort-by":"fName","dense":"","footer-props":{
                    itemsPerPageOptions:[20, 40, -1],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                       'items-per-page-text':'Domæner'
                  }},scopedSlots:_vm._u([{key:"item.read",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.changePermission(item)}},model:{value:(item.actions.read),callback:function ($$v) {_vm.$set(item.actions, "read", $$v)},expression:"item.actions.read"}})]}},{key:"item.write",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.changePermission(item)}},model:{value:(item.actions.write),callback:function ($$v) {_vm.$set(item.actions, "write", $$v)},expression:"item.actions.write"}})]}},{key:"item.update",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.changePermission(item)}},model:{value:(item.actions.update),callback:function ($$v) {_vm.$set(item.actions, "update", $$v)},expression:"item.actions.update"}})]}},{key:"item.delete",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.changePermission(item)}},model:{value:(item.actions.delete),callback:function ($$v) {_vm.$set(item.actions, "delete", $$v)},expression:"item.actions.delete"}})]}}])})],1),_c('v-dialog',{staticClass:"transparent",attrs:{"max-width":"640px"},model:{value:(_vm.newAreaDialog),callback:function ($$v) {_vm.newAreaDialog=$$v},expression:"newAreaDialog"}},[_c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_vm._v("Opret rettighedsdefinition "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.newAreaDialog = !_vm.newAreaDialog}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Områdenavn","outlined":"","dense":""},model:{value:(_vm.newArea.name),callback:function ($$v) {_vm.$set(_vm.newArea, "name", $$v)},expression:"newArea.name"}}),_c('v-data-table',{attrs:{"headers":_vm.newArea.headers,"items":_vm.newArea.roles,"sort-by":"fName","sort-desc":""},scopedSlots:_vm._u([{key:"item.read",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})]}},{key:"item.write",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},model:{value:(item.write),callback:function ($$v) {_vm.$set(item, "write", $$v)},expression:"item.write"}})]}},{key:"item.update",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},model:{value:(item.update),callback:function ($$v) {_vm.$set(item, "update", $$v)},expression:"item.update"}})]}},{key:"item.delete",fn:function(ref){
                  var item = ref.item;
return [_c('v-checkbox',{staticClass:"pt-0 mt-0",attrs:{"dense":"","hide-details":""},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})]}}])}),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.newAreaDialog = !_vm.newAreaDialog}}},[_vm._v("Annuller")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addArea()}}},[_vm._v("GEM")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }