<template>
    <v-data-table
        :items="items"
        :headers="headers"
        dense
        class="transparent"
        :footer-props="{itemsPerPageOptions:[30, -1]}"
        :loading="commitsLoading"
        mobile-breakpoint="0"
    >
        <template
            v-slot:item.hash="{item}">
            <div class="mySimpleTimeline">
                <a :href="'https://bitbucket.org/Rasmus_Nielsen/kk-outreach/commits/'+item.hash" target="_blank"
                   class="text--code">{{item.hash.slice(0,7)}}</a>
            </div>
        </template>

        <template
            v-slot:item.author="{item}">
            <v-avatar
                rounded
                size="16"
                class="mr-2">
                <v-img v-if="item.author.user" :src="item.author.user.links.avatar.href"></v-img>
            </v-avatar>
            <a v-if="item.author.user" :href="item.author.user.links.html.href" target="_blank" class="white--text">{{item.author.user.display_name}}</a>
        </template>
        <template v-slot:item.date="{item}">
            {{UTCtoLocale(item.date)}}
        </template>
    </v-data-table>
</template>

<script>
    import api
        from "../../services/api";

    export default {
        name: "commitList",
        data() {
            return {
                items: [],
                headers: [
                    {
                        text: 'Commit',
                        value: 'hash',
                        width: 60,
                    },
                    {
                        text: 'Author',
                        value: 'author',
                        width: 200,
                    },
                    {
                        text: 'Message',
                        value: 'message',
                    },
                    {
                        text: 'Date',
                        value: 'date',
                        width: 250,
                    },
                ],
                commitsLoading: false,
            }
        },
        methods: {
            getCommits() {
                this.commitsLoading = true
                api.get('commits')
                    .then(response => {
                        this.items = response.data.values
                        this.commitsLoading = false
                    })
            },
            UTCtoLocale(date) {
                let c = new Date(date).toRegular()
                return `${c.d}-${c.m}-${c.Y} kl. ${c.H}:${c.i}:${c.s}`
            }
        },
        mounted() {
            this.getCommits()
        }

    }
</script>
<style>

    :root {
        --timeline-dot-size: 5px;
    }

    .text--code {
        font-family: Consolas, monospace !important;
        color: #fff !important;
    }

    .mySimpleTimeline {
        position: relative;
        padding-left: calc(var(--timeline-dot-size) * 2);
        margin-left: calc(var(--timeline-dot-size) * 2);
        min-height: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
    }

    .mySimpleTimeline:before {
        position: absolute;
        content: '';
        transform: translateX(-50%);
        left: 0;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, .12);
    }

    .mySimpleTimeline:after {
        position: absolute;
        content: '';
        width: var(--timeline-dot-size);
        height: var(--timeline-dot-size);
        transform: translateX(-50%);
        left: 0;
        border-radius: 50%;
        background: #1db819 !important;
    }

</style>