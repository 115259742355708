<template>
    <v-data-table
        :items="emails"
        :headers="emailHeaders"
        dense
        class="transparent"
    >
        <template v-slot:item.body="{item}">
            <v-dialog>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                    >Se mail
                    </v-btn>
                </template>
                <v-sheet class="mailDialog">
                    <v-toolbar>
                        <v-toolbar-title>{{item.subject}} &lt;{{item.recipient}}&gt;</v-toolbar-title>
                    </v-toolbar>
                    <div class="mailContent" v-html="item.body"></div>
                </v-sheet>
            </v-dialog>
        </template>
    </v-data-table>
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "mailHistory",
        data() {
            return {
                emails: [],
                emailHeaders: [
                    {text: 'Dato', value: 'createdDate', width: '180'},
                    {text: 'Modtager', value: 'recipient',},
                    {text: 'Emne', value: 'subject',},
                    {text: 'Indhold', value: 'body',},
                ]
            }
        },
        methods: {
            getEmails() {
                api.get('/mail-service/')
                    .then(response => {
                        this.emails = response.data
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastError', err.message)
                    })
            }
        },
        mounted() {
            this.getEmails()
        }
    }
</script>

<style>
    .mailDialog {
    }

    .mailDialog .mailContent {
        background: #015d76;
        color: #333 !important;
        overflow: auto;
    }
</style>