<template>
    <v-card color="transparent" elevation="0">
        <v-toolbar elevation="0" color="transparent" dense>
            <v-toolbar-items>
                <v-btn
                    v-for="(role,idx) in permissions"
                    :key="role.role"
                    @click="setRole(idx)"
                    elevation="0"
                    color="transparent"
                >
                    {{role.name}}
                </v-btn>
            </v-toolbar-items>
            <v-spacer/>
            <v-btn right text @click="newAreaDialog = !newAreaDialog">
                <v-icon left>mdi-plus</v-icon>
                Område
            </v-btn>
        </v-toolbar>
        <v-divider/>


        <v-sheet
            class="transparent">
            <v-card-subtitle>
                Du ser nu rettigheder for&nbsp;<span class="font-weight-bold text-decoration-underline">{{permissions[currentRole].name}}</span>
                <v-spacer/>

            </v-card-subtitle>
            <v-divider/>
            <v-data-table
                :items="permissions[currentRole].permissions"
                :headers="permissionHeaders"
                class="transparent"
                sort-by="fName"
                dense
                :footer-props="{
                        itemsPerPageOptions:[20, 40, -1],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-chevron-double-left',
                        lastIcon: 'mdi-chevron-double-right',
                           'items-per-page-text':'Domæner'
                      }"
            >
                <template v-slot:item.read="{item}">
                    <v-checkbox
                        dense
                        hide-details
                        v-model="item.actions.read"
                        class="pt-0 mt-0"
                        @click="changePermission(item)"
                    />
                </template>
                <template v-slot:item.write="{item}">
                    <v-checkbox
                        dense
                        hide-details
                        v-model="item.actions.write"
                        class="pt-0 mt-0"
                        @click="changePermission(item)"
                    />
                </template>
                <template v-slot:item.update="{item}">
                    <v-checkbox
                        dense
                        hide-details
                        v-model="item.actions.update"
                        class="pt-0 mt-0"
                        @click="changePermission(item)"
                    />
                </template>
                <template v-slot:item.delete="{item}">
                    <v-checkbox
                        dense
                        hide-details
                        v-model="item.actions.delete"
                        class="pt-0 mt-0"
                        @click="changePermission(item)"
                    />
                </template>
            </v-data-table>
        </v-sheet>

        <v-dialog
            v-model="newAreaDialog"
            max-width="640px"
            class="transparent"
        >
            <v-card light>
                <v-card-title>Opret rettighedsdefinition
                    <v-spacer/>
                    <v-btn @click="newAreaDialog = !newAreaDialog" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field label="Områdenavn" outlined dense v-model="newArea.name"/>
                    <v-data-table
                        :headers="newArea.headers"
                        :items="newArea.roles"
                        sort-by="fName"
                        sort-desc
                    >
                        <template v-slot:item.read="{item}">
                            <v-checkbox
                                dense
                                hide-details
                                v-model="item.read"
                                class="pt-0 mt-0"
                            />
                        </template>
                        <template v-slot:item.write="{item}">
                            <v-checkbox
                                dense
                                hide-details
                                v-model="item.write"
                                class="pt-0 mt-0"
                            />
                        </template>
                        <template v-slot:item.update="{item}">
                            <v-checkbox
                                dense
                                hide-details
                                v-model="item.update"
                                class="pt-0 mt-0"
                            />
                        </template>
                        <template v-slot:item.delete="{item}">
                            <v-checkbox
                                dense
                                hide-details
                                v-model="item.delete"
                                class="pt-0 mt-0"
                            />
                        </template>
                    </v-data-table>
                    <v-divider class="mb-4"/>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="newAreaDialog = !newAreaDialog" text color="red">Annuller</v-btn>
                        <v-btn @click="addArea()" color="primary">GEM</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>

</template>

<script>
    import userManager from "../../../services/userManager";

    export default {
        name: "rbac",
        data() {
            return {
                currentRole: 0,
                permissions: [{}],
                permissionHeaders: [
                    {text: 'Navn', value: 'fName', sortable: false, width: 200},
                    {text: 'Område', value: 'component', sortable: false, width: 200},
                    {text: 'Read', value: 'read', sortable: false},
                    {text: 'Write', value: 'write', sortable: false},
                    {text: 'Update', value: 'update', sortable: false},
                    {text: 'Delete', value: 'delete', sortable: false},
                ],
                newAreaDialog: false,
                newArea: {
                    name: '',
                    roles: [],
                    headers: [
                        {text: 'Rolle', value: 'name', width: 200},
                        {text: 'Read', value: 'read', sortable: false},
                        {text: 'Write', value: 'write', sortable: false},
                        {text: 'Update', value: 'update', sortable: false},
                        {text: 'Delete', value: 'delete', sortable: false},
                    ],
                }
            }
        },
        methods: {
            setRole(idx) {
                this.currentRole = idx
            },
            addArea() {
                userManager.addArea(this.newArea)
                    .then(response => {
                        if (response) {
                            this.getPermissions()
                        }
                    })
            },
            log(obj) {
                console.log(obj)
            },
            getPermissions() {
                userManager.getPermissions()
                    .then(permissions => {
                        this.permissions = permissions
                    })
            },
            changePermission(item) {
                item.role = this.permissions[this.currentRole].role
                userManager.changePermission(item)
                    .then(response => {
                        console.log('ændret')
                    })
            }
        },
        mounted() {
            userManager.getRoles()
                .then(roles => {
                    this.newArea.roles = roles
                })
            this.getPermissions()
        }
    }
</script>

<style scoped>

</style>