<template>
    <v-container class="pa-sm-1 pa-md-1 pa-lg-8 pa-xl-8 pt-sm-12 pt-md-12 pt-12">
        <v-row>
            <v-col sm="12" md="10" lg="10" xl="10" class="mx-auto">
                <v-sheet class="glass--bg ">
                    <v-toolbar class="transparent" elevation="2">
                        <v-toolbar-title>Adminstration</v-toolbar-title>
                    </v-toolbar>
                    <v-tabs v-model="tab" vertical class="transparent">
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-sitemap</v-icon>
                            Brugere
                        </v-tab>
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-account-alert</v-icon>
                            Rollerettigheder
                        </v-tab>
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-playlist-plus</v-icon>
                            Tilføj presssider
                        </v-tab>
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-github</v-icon>
                            Versionering
                        </v-tab>
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-account-multiple</v-icon>
                            Loginhistorik
                        </v-tab>
                        <v-tab class="white--text align-self-start">
                            <v-icon color="white" left>mdi-archive</v-icon>
                            Mailhistorik
                        </v-tab>
                        <v-tabs-items v-model="tab" touchless vertical>
                            <v-tab-item class="pt-4">
                                <user-management/>
                            </v-tab-item>
                            <v-tab-item class="pt-4">
                                <rbac/>
                            </v-tab-item>
                            <v-tab-item class="pt-4">
                                <add-sites/>
                            </v-tab-item>
                            <v-tab-item class="pt-4">
                                <commit-list/>
                            </v-tab-item>
                            <v-tab-item class="pt-4">
                                <user-log/>
                            </v-tab-item>
                            <v-tab-item class="pt-4">
                                <mail-history/>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api from "../services/api";
    import AddSites from "../components/sites/addSites";
    import UserManagement from "../components/user/userManagement";
    import CommitList from "../components/admin/commitList";
    import UserInfo from "../components/user/userInfo";
    import Rbac from "../components/admin/rbac/rbac";
    import UserLog from "../components/admin/userLog";
    import MailHistory from "../components/mail/mailHistory";

    export default {
        name: "Admin",
        components: {MailHistory, UserLog, Rbac, UserInfo, CommitList, UserManagement, AddSites},
        data() {
            return {
                tab: 5
            }
        }
    }
</script>

<style >
    .v-tabs-items {
        padding:0 15px;
    }
    .v-item-group.theme--dark.v-slide-group.v-tabs-bar.primary--text{
        background:transparent !important;
    }
</style>