import api from "./api";
import {createUserWithEmailAndPassword, getAuth} from "firebase/auth";
import store from '@/store'

class userManager {
    activateUser(uid) {
        api.put(`user-management/users/${uid}/activate`)
          .then(response => {
              store.dispatch('toasts/toastSuccess', 'Bruger aktiveret')
          })
    }

    deactivateUser(uid) {
        api.put(`user-management/users/${uid}/deactivate`)
          .then(response => {
              store.dispatch('toasts/toastSuccess', 'Bruger deaktiveret')
          })
    }

    createUser(user, firebaseInfo) {
        return new Promise((resolve, reject) => {
            let data = {
                name: user.name,
                mail: user.mail,
                uid: firebaseInfo.user.uid,
                cvr: user.company.cvr
            }
            api.post('users', data)
              .then(response => {
                  resolve(response.data)
              })
              .catch(error => {
                  reject(error.code)
              })
        })
    }

    createFirebaseUser(user) {
        return new Promise((resolve, reject) => {
            const auth = getAuth()
            createUserWithEmailAndPassword(auth, user.mail, user.pass)
              .then(response => {
                  this.createUser(user, response)
                    .then(localResponse => {
                        resolve(localResponse)
                    })

                  store.dispatch('auth/passwordResetOnCreate', response.user.email)
              })
              .catch(error => {
                  switch (error.code) {
                      case 'auth/email-already-in-use':
                          store.dispatch('toasts/toastError', 'E-mail allerede i brug.')
                          this.errorMessage = 'E-mail allerede i brug.'
                          this.userCreateLoad = false
                          break;
                      case 'auth/missing-email':
                          this.errorMessage = 'E-mail mangler.'
                          this.userCreateLoad = false
                          break;
                      default:
                          break;
                  }
              })
        })
    }


    setUserRole(uid, role) {
        api.put(`user-management/users/${uid}/role`, {role: role})
          .then(response => {
              response.data && store.dispatch('toasts/toastSuccess', 'Brugerrolle ændret')
          })
          .catch(error => {
              switch (error.data.code) {
                  case 'rbac/unauthorized':
                      store.dispatch('toasts/toastError', error.data.message)
                      break;
                  default:
                      store.dispatch('toasts/toastError', 'Der skete en fejl')
                      break;
              }
          })
    }

    getRoles() {
        return new Promise((resolve, reject) => {
            api.get('user-management/roles')
              .then(response => {
                  resolve(response.data)
              })
              .catch(error => {
                  reject(error)
                  store.dispatch('toasts/toastError', error.message)
              })
        })
    }


    getPermissions() {
        return new Promise((resolve, reject) => {
            api.get('user-management/permissions')
              .then(response => {
                  resolve(response.data)
              })
              .catch(error => {
                  reject(error)
                  store.dispatch('toasts/toastError', error.message)
              })
        })
    }

    addArea(payload) {
        return new Promise((resolve, reject) => {
            api.post('user-management/permissions', payload)
              .then(response => {
                  resolve(response.data)
                  store.dispatch('toasts/toastSuccess', 'Nyt område tilføjet')
              })
              .catch(error => {
                  reject(error)
                  store.dispatch('toasts/toastError', error.message)
              })
        })
    }

    changePermission(payload) {
        return new Promise((resolve, reject) => {
            api.put('user-management/permissions', payload)
              .then(response => {
                  resolve(response.data)
                  store.dispatch('toasts/toastSuccess', 'Brugerrettigheder ændret')
              })
              .catch(error => {
                  reject(error)
                  store.dispatch('toasts/toastError', error.message)
              })
        })
    }

}

export default new userManager();