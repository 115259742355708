<template>
    <v-data-table
        class="transparent"
        :items="userLog"
        :headers="userLogHeaders"
        dense
        sort-desc
        sort-by="created"
        :footer-props="{
                    itemsPerPageOptions:[20, 40, 60],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right','items-per-page-text':'Logins'
                }"
    />
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "userLog",
        data() {
            return {
                userLog: [],
                userLogHeaders: [
                    {text: 'Navn', value: 'name'},
                    {text: 'Loggede på', value: 'created'},
                    {text: 'Login udløber', value: 'expires'},
                ]
            }
        },
        methods: {
            getUserLog() {
                api.get('auth/userLog')
                    .then(response => {
                        this.userLog = response.data
                    })
            }
        },
        mounted() {
            this.getUserLog()
        },
    }
</script>

<style scoped>

</style>