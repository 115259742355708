<template>
    <v-card v-if="$store.state.auth.status.loggedIn" class="mb-5">
        <v-card-text>
            <v-text-field
                label="id"
                v-model="userId"
            ></v-text-field>
            <p>{{endPoint}}</p>
            <v-btn @click="getUser()" :loading="loading">sum btn</v-btn>
            <pre v-html="prettify"></pre>
        </v-card-text>
    </v-card>
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "userInfo",
        data() {
            return {
                json: [],
                userId: '',
                loading: false,


            }
        },
        methods: {

            getUser() {
                this.loading = true
                api.get('users/' + this.userId)
                    .then(response => {
                        this.json = response.data
                        this.loading = false
                    })
            },
        },
        computed: {
            prettify: function () {
                return JSON.stringify(this.json, null, 2)
            },
            endPoint: function () {
                return 'https://devapi.kompetencekanalen.dk/users' + (this.userId.trim() === '' ? '' : '/' + this.userId)
            }
        }
    }
</script>

<style scoped>

</style>