<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="0"
                tile
                color="transparent"
            >
                <v-icon class="mr-2">mdi-account-plus</v-icon>
                Opret bruger
            </v-btn>
        </template>

        <v-card
            elevation="0"
            class="gradient--background"
        >
            <v-card-title>Opret din KK-konto</v-card-title>
            <v-card-text>
                <v-subheader>KK test cvr: 32653308</v-subheader>
                <v-text-field
                    :rules="[rules.required]"
                    append-icon="mdi-factory"
                    dense
                    label="CVR"
                    name="cvr"
                    outlined
                    v-model="cvr"
                    :loading="cvrLoad"
                    @input="cvr.length === 8 && fetchCvr()"

                />
                <v-text-field
                    :rules="[rules.required]"
                    append-icon="mdi-account"
                    dense
                    label="Kontaktperson navn"
                    name="name"
                    outlined
                    v-model="user.name"
                    @keydown.enter="submit()"

                />
                <v-text-field
                    :rules="[rules.required, rules.email, mailMatchRule]"
                    append-icon="mdi-email"
                    dense
                    label="E-mail"
                    name="email"
                    outlined
                    v-model="user.mail"
                    @keydown.enter="submit()"
                />
                <v-text-field
                    :rules="[rules.required, rules.email,mailMatchRule]"
                    append-icon="mdi-email"
                    dense
                    label="Gentag e-mail"
                    name="email"
                    outlined
                    v-model="user.mailConfirm"
                    @keydown.enter="submit()"
                />
                <div v-if="user.company !== null">
                    <span class="font-weight-bold text-decoration-underline">Bruger tilføjes til firma</span><br><br>
                    <span class="font-weight-bold">{{user.company.name}}</span><br>
                    {{user.company.street}} {{user.company.streetNumber}}<br>
                    {{user.company.postalCode}} {{user.company.city}}<br>
                    Virksomhedsform: {{user.company.type}}<br>
                    Hovedbranche: {{user.company.business}}<br>
                    Ansatte: {{user.company.employees}}<br>
                    DK-{{user.company.cvr}}<br>
                </div>
            </v-card-text>
            <v-card-actions class="pa-4 pt-0">
                <v-spacer/>
                <v-btn
                    color="primary"
                    :disabled="cvrLoad"
                    :loading="userCreateLoad"
                    dark
                    elevation="0"
                    @click="submit()">
                    Opret bruger
                </v-btn>
            </v-card-actions>
            <v-alert
                dense
                text
                type="success"
                dismissible
                v-if="userCreated"
            >
                Bruger oprettet
            </v-alert>
        </v-card>
    </v-dialog>
</template>

<script>
    import api from "../../services/api";
    import userManager from "../../services/userManager";

    export default {
        name: "addUser",
        data() {
            return {
                dialog: false,
                hidePass: true,
                cvr: '',
                cvrLoad: false,
                userInitial: {
                    name: '',
                    mail: '',
                    mailConfirm: '',
                    pass: '123awfe7iy23uioh4',
                    company: null,
                },
                user: {
                    name: '',
                    mail: '',
                    mailConfirm: '',
                    pass: '123awfe7iy23uioh4',
                    company: null,
                },
                errorMessage: '',
                userCreateLoad: false,
                userCreated: false,
                rules: {
                    required: value => !!value || 'Påkrævet.',
                    min8: v => v.length >= 8 || 'Min. 8 tegn',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Invalid e-mail.'
                    },
                },
            }
        },
        methods: {
            submit() {
                this.userCreateLoad = true
                userManager.createFirebaseUser(this.user)
                  .then(response => {
                      if (response.status === 'success') {
                          this.$store.dispatch('toasts/toastSuccess', 'Bruger oprettet')
                          this.user = this.userInitial
                          this.userCreateLoad = false
                          this.dialog = false
                      }
                  })
            },
            fetchCvr() {
                this.cvrLoad = true
                api.get('cvr/' + this.cvr)
                  .then(response => {
                      this.user.company = response.data.organisation
                      this.cvrLoad = false
                  })
            }
        },
        computed: {
            mailMatchRule() {
                return (this.user.mail === this.user.mailConfirm) || (`E-mail skal være ens`)
            },
            passMatchRule() {
                return (this.user.pass === this.user.passConfirm) || (`Adgangskoder skal være ens`)
            }
        }
    }
</script>

<style scoped>

</style>